// Dependencies
import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from '../../actions/modalActions';
import { REPORTS_LAMBDA_URL, REPORT_LAMBDA_API_KEY } from '../../constants/infra';

const reportRequest = async (organizationId, filters) => {
  const res = await axios.post(REPORTS_LAMBDA_URL, {
    organizationId,
    HYPERCUBE_API_KEY: REPORT_LAMBDA_API_KEY,
    CLIENT_MONTH: '3',
    LANG: 'en',
    ...filters,
  }, {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*',
    },
    responseType: 'arraybuffer',
  });

  return res;
};

function PageHeaderTitle(props) {
  const dispatch = useDispatch();
  const filtersState = useSelector((state) => state.filters);
  const {
    title, pretitle, actions,
  } = props;

  const openLoadingModal = async () => {
    dispatch(
      toggleModal({ type: 'loading' }),
    );

    const filters = filtersState.inUse ? {
      ...(filtersState.start && filtersState.end && {
        startDate: filtersState.start,
        endDate: filtersState.end,
      }),
      ...(filtersState.integrationId && {
        integrationId: filtersState.integrationId,
      }),
      ...(filtersState.vendors && {
        vendors: filtersState.vendors.map((vendor) => vendor._id),
      }),
    } : {};

    const reportResponse = await reportRequest(actions.organizationId, filters);
    if (reportResponse.data && reportResponse.status === 200) {
      // eslint-disable-next-line max-len
      const pdfBlob = new Blob([reportResponse.data], { type: 'application/pdf' });

      const pdfUrl = URL.createObjectURL(pdfBlob);

      window.open(pdfUrl);

      const downloadLink = document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.download = 'report.pdf';

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);

      setTimeout(() => URL.revokeObjectURL(pdfUrl), 1000);
      dispatch(toggleModal('close'));
    }
  };

  return (
    <div className="header mt-md-5">
      <div className="header-body">
        <div className="d-flex justify-content-between">
          <div>
            <h6 className="header-pretitle">{pretitle}</h6>
            <h1 className="header-title">{title}</h1>
          </div>
          <div>
            {
              actions?.downloadBtn ? (
                <div className="align-self-center">
                  <Link onClick={openLoadingModal} className="btn btn-sm btn-primary mr-2 ">
                    {actions?.downloadBtn}
                  </Link>
                </div>
              ) : null
            }
          </div>

        </div>
      </div>
    </div>
  );
}

export default PageHeaderTitle;

PageHeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  pretitle: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    filtersLabel: PropTypes.string,
    downloadBtn: PropTypes.string,
    organizationId: PropTypes.string,
  }).isRequired,
};
