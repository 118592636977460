/* eslint-disable react/require-default-props */
// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components, assets, actions, etc..
import LineSpinner from '../spinners/LineSpinner';
import SavingActivityItem from '../activity/SavingActivityItem';
import ItemFiller from '../activity/ItemFiller';

function SavingsWidget({ savings = [] }) {
  const { t } = useTranslation();
  const renderList = () => {
    if (!savings.length) return <ItemFiller title={t('components.widgets.savingWidget.noFindingsYet')} description={t('components.widgets.savingWidget.pleaseAdd')} />;

    return savings.map((saving) => (
      <SavingActivityItem
        key={saving._id}
        title={saving.title}
        icon={saving.vendorIcon}
        createdAt={saving.updatedAt}
      />
    ));
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">{t('components.widgets.savingWidget.yourFindings')}</h4>
        <Link to="/dashboard/savings" className="btn btn-sm btn-primary mr-2">
          {t('components.widgets.savingWidget.goToSavings')}
        </Link>
        <Link to="/dashboard/security" className="btn btn-sm btn-secondary">
          {t('components.widgets.savingWidget.goToSecurity')}
        </Link>
      </div>
      <div className="card-body">
        <div className="checklist">
          { savings ? (
            <div className="list-group list-group-flush list-group-activity my-n3">
              { renderList() }
            </div>
          ) : (
            <LineSpinner name={t('views.dashboard.savings.spinners.savings')} />
          )}
        </div>
      </div>
    </div>
  );
}

export default SavingsWidget;

SavingsWidget.propTypes = {
  savings: PropTypes.arrayOf(PropTypes.object),
};
