// Dependencies
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Onboarding() {
  const currentStepNumber = useSelector((state) => state.onboarding.currentStep);
  const steps = useSelector((state) => state.onboarding.steps);
  const [currentStep, setCurrentStep] = useState();

  useEffect(() => {
    const stepName = Object.keys(steps)[currentStepNumber - 1];
    setCurrentStep(steps[stepName]);
  }, [currentStepNumber]);

  const renderOnboarding = () => (
    <>
      <Outlet />
      <Navigate to={`/onboarding${currentStep?.route || '/create-organization'}`} />
    </>
  );

  // User should be authenticated since its being redirect from auth0 post login
  return renderOnboarding();
}

export default Onboarding;
