/* eslint-disable max-len */
import React, { useState, useCallback } from 'react';
import {
  Dropdown, DropdownButton, ButtonGroup, FormControl, Spinner,
  Badge,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

function SearchableItem({ selectionItem, onItemAction }) {
  return (
    <div className="d-flex justify-content-center mt-1">
      <Badge
        style={{
          height: 20,
          color: 'white',
        }}
        pill
        bg="secondary"
        onClick={() => onItemAction()}
      >
        {selectionItem}
        {' '}
      </Badge>

    </div>
  );
}
function SearchableDropdown({
  title, optionsList, onSelectionChange, selection, onSearch, isLoading,
}) {
  const [searchQuery, setSearchQuery] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce((keyword) => {
    onSearch(keyword);
  }, 500), [onSearch]);

  const onHandleSearch = (keyword) => {
    setSearchQuery(keyword);
    debouncedSearch(keyword);
  };

  return (
    <DropdownButton
      className="dropdown-button-style"
      as={ButtonGroup}
      id="dropdown-basic-button"
      title={

        selection ? <SearchableItem selectionItem={selection} onItemAction={console.log} /> : title

      }
    >
      <FormControl
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => onHandleSearch(e.target.value)}
        className="mx-3 my-2 w-auto"
      />
      {isLoading ? (
        <div className="text-center my-2">
          <Spinner animation="border" size="sm" />
        </div>
      ) : (
        optionsList.map((item) => (
          <Dropdown.Item
            key={item}
            onClick={(e) => onSelectionChange(e.target.text)}
          >
            {item}
          </Dropdown.Item>
        ))
      )}
    </DropdownButton>
  );
}

export default SearchableDropdown;

SearchableDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  selection: PropTypes.string.isRequired,
  optionsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
