// Dependencies
import '@fortawesome/fontawesome-free/js/all';
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
// Components
import ModalWrapper from '../components/modals/ModalWrapper';
import ScrollToTop from '../helpers/ScrollToTop';
import Landing from '../views/landing/Landing';
import Dashboard from '../views/dashboard/Dashboard';
import Home from '../views/dashboard/Home';
import './App.css';
import Onboarding from '../views/onboarding/Onboarding';

const Integrations = React.lazy(() => import('../views/dashboard/Integrations'));
const AddIntegrations = React.lazy(() => import('../views/onboarding/AddIntegrations'));
const Savings = React.lazy(() => import('../views/dashboard/Savings'));
const Transactions = React.lazy(() => import('../views/dashboard/Transactions'));
const Profile = React.lazy(() => import('../views/dashboard/Profile'));
const Notifications = React.lazy(() => import('../views/dashboard/Notifications'));
const Api = React.lazy(() => import('../views/dashboard/Api'));
const Security = React.lazy(() => import('../views/dashboard/Security'));
const Organizations = React.lazy(() => import('../views/dashboard/Organizations'));
const OrganizationsDetails = React.lazy(() => import('../views/dashboard/OrganizationsDetails'));
const Resources = React.lazy(() => import('../views/dashboard/Resources'));
const CreateOrganization = React.lazy(() => import('../views/onboarding/CreateOrganization'));
const InviteCoworkers = React.lazy(() => import('../views/onboarding/InviteCoworkers'));

const ErrorView = React.lazy(() => import('../views/error/Error'));

function App() {
  return (
    <>
      <ScrollToTop />
      <ModalWrapper onHide={null} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="onboarding" element={<Onboarding />}>
            <Route path="create-organization" element={<CreateOrganization />} />
            <Route path="invite-coworkers" element={<InviteCoworkers />} />
          </Route>
          <Route path="dashboard" element={<Dashboard />}>
            <Route index element={<Home />} />
            <Route path="integrations" element={<Integrations />} />
            <Route path="integrations/add" element={<AddIntegrations />} />
            <Route path="savings" element={<Savings />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="profile" element={<Profile />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="api" element={<Api />} />
            <Route path="security" element={<Security />} />
            <Route path="organizations" element={<Organizations />} />
            <Route path="organization/:id" element={<OrganizationsDetails />} />
            <Route path="resources" element={<Resources />} />
          </Route>
          <Route path="error" element={<ErrorView />} />
          <Route path="*" element={<ErrorView />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
