/* eslint-disable indent */
// Dependencies
import React, { Suspense, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Steps } from 'intro.js-react';
// Components, actions, assets, etc..
import { useNavigate } from 'react-router-dom';
import ActivityFeed from '../../components/activity/ActivityFeed';
import CurrentSpendingBarChart from '../../components/charts/CurrentSpendingBarChart';
import DataGraph from '../../components/charts/DataGraph';
// eslint-disable-next-line import/no-cycle
import Table from '../../components/common/table/Table';
import HomeFiller from '../../components/fillers/HomeFiller';
import transactionColumns from '../../components/transactions/transactionColumns';
import MonthTotal from '../../components/widgets/MonthTotal';
import SavingsWidget from '../../components/widgets/SavingsWidget';
import PageHeaderTitle from './PageHeaderTitle';
import LineSpinner from '../../components/spinners/LineSpinner';
import TableResultFiller from '../../components/fillers/TableResultFiller';
import AccountsAndIntegrations from '../../components/widgets/AccountsAndIntegrations';
import ActiveThreats from '../../components/widgets/ActiveThreats';
import CreatedVsResolved from '../../components/widgets/CreatedVsResolved';
import { getLastMonthsGroupedExpenses } from '../../helpers/date';
import { USD_EXCHANGE_RATE } from '../../constants/exchangesRate';
import DashboardFilters from '../../components/widgets/DashboardFilters';

const firstSteps = [
  {
    title: 'Hi there!',
    element: '#home-filler-container',
    intro:
      'Welcome to Obok! Discover our complete platform for modern cloud management 👋.',
  },
  {
    title: 'Getting started',
    element: '#get-started-button',
    intro:
      'Create your first integration and get insights into your data, applications, and infrastructure in an automated way with AI.',
  },
];

const observableMonths = 3;
function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const savings = useSelector((state) => state.savings?.all);
  const securities = useSelector((state) => state.security?.all);
  const dataWidgets = useSelector((state) => state.widgets);

  const [titleFilter, setTitleFilter] = useState(null);
  const searchFilterObject = {
    titleFilter,
    setTitleFilter,
  };

  const totalSpendingBalance = useSelector((state) => state.transactions?.total);

  const transactionObject = useSelector(
    (state) => state.transactions?.transactions,
  );

  const latestTransactions = transactionObject?.result;

  const totalTransactions = transactionObject?.edges?.total;
  // eslint-disable-next-line max-len
  const lastThreeMonthsTransactions = getLastMonthsGroupedExpenses(
    latestTransactions,
    observableMonths,
  );

  const latestNotifications = useSelector(
    (state) => state.notifications?.latest,
  );
  const hasIntegrations = useSelector(
    (state) => state.integrations?.edges?.total,
  );
  const organizationsData = useSelector(
    (state) => state.organizations.currentOrganization,
  );
  const { expensesByVendor } = useSelector((state) => state.charts);
  const { user } = useSelector((state) => state.auth);
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const headerTranslations = {
    integration: t('views.dashboard.transactions.integration'),
    category: t('views.dashboard.transactions.category'),
    spending: t('views.dashboard.transactions.spending'),
    currency: t('views.dashboard.transactions.currency'),
    purchasedAt: t('views.dashboard.transactions.purchasedAt'),
  };

  useEffect(() => {
    setTimeout(() => {
      window.hj('identify', user._id, {
        email: user.email,
      });
    }, 3000);
    if (user?.intro) {
      setStepsEnabled(user.intro?.active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.intro]);

  const spendingTitle = `${t('views.dashboard.home.spendingByIntegration')} (${USD_EXCHANGE_RATE})`;
  const getCheckList = () => {
    if (savings?.length && securities?.length) {
      return savings
        .concat(securities)
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        .slice(0, 5);
    }
    if (savings?.length) return savings.slice(0, 5);
    if (securities?.length) return securities.slice(0, 5);
    return null;
  };

  const renderTransactionFiller = () => {
    if (transactionObject?.edges?.total === 0) return <TableResultFiller />;
    return null;
  };
  const renderTable = () => (
    <>
      <Suspense>
        <Table
          items={latestTransactions?.slice(0, 10)}
          columns={transactionColumns(headerTranslations)}
          model="transactions"
          total={totalTransactions}
          searchFilterObject={searchFilterObject}
          paginate={false}
        />
      </Suspense>
      {renderTransactionFiller()}
    </>
  );

  const renderDataWidgets = () => (
    <div className="row">
      <div className="col-sm-12 col-md-8">
        {dataWidgets.threatsIdentified && (
          <CreatedVsResolved
            threatsIdentified={dataWidgets.threatsIdentified}
          />
        )}
      </div>
      <div className="col-sm-12 col-md-4 min-height-dashboard-charts">
        {dataWidgets.threatsActive && (
          <ActiveThreats
            total={dataWidgets?.threatsActive?.total}
            priorities={dataWidgets?.threatsActive?.priorities}
          />
        )}
      </div>
    </div>
  );

  const renderComponents = () => {
    let accountsContent;
    const filtersSection = <DashboardFilters t={t} organizationId={organizationsData._id} />;
    if (dataWidgets.accounts !== 0) {
      accountsContent = (
        <AccountsAndIntegrations accounts={dataWidgets.accounts} />
      );
    }

    return (
      <>
        {accountsContent}
        {filtersSection}
        <div className="row" id="totalSpending">
          <MonthTotal
            titles={[
              t('views.dashboard.home.spending'),
              t('views.dashboard.home.spendingLastMonth'),
              t('views.dashboard.home.spendingCurrentMonth'),
            ]}
            type="amount"
            totals={totalSpendingBalance}
          />
        </div>
        {lastThreeMonthsTransactions?.monthNames.every(
          (month) => month !== 'undefined',
        ) ? (
          <div className="row">
            <div className="col-12 col-xl-12" id="expensesByVendor">
              <DataGraph
                data={{ lastThreeMonthsTransactions, expensesByVendor }}
                title={spendingTitle}
                charts={[CurrentSpendingBarChart]}
              />
            </div>
          </div>
        ) : null}

        {renderDataWidgets()}

        <div className="row d-none">
          <div className="col-12">{renderTable()}</div>
        </div>
      </>
    );
  };

  const handleExit = () => {
    navigate('/dashboard/integrations/add');
  };

  const renderIntroSteps = () => (
    <Steps
      enabled={stepsEnabled}
      steps={firstSteps}
      initialStep={0}
      onExit={handleExit}
    />
  );

  const renderHome = () => {
    const intro = renderIntroSteps();
    if (hasIntegrations) {
      return <>{renderComponents()}</>;
    }
    if (latestTransactions === null || latestTransactions === undefined) {
      return <LineSpinner name={t('views.dashboard.home.spinners')} />;
    }
    return (
      <>
        <HomeFiller to="/dashboard/integrations/add" />
        {intro}
      </>
    );
  };

  return (
    <div className="container">
      <PageHeaderTitle
        title={t('views.dashboard.home.title')}
        pretitle={t('views.dashboard.home.overview')}
        actions={{
          filtersLabel: t('views.dashboard.filters.title'),
          downloadBtn: t('views.dashboard.download_report'),
          organizationId: organizationsData._id,
        }}
      />
      <div className="container">
        {renderHome()}
        <div className="row">
          <ActivityFeed
            givenName={user.givenName || user.given_name}
            familyName={user.familyName || user.family_name}
            notifications={latestNotifications}
            icon={user.picture}
            length={4}
          />
          <div className="col-12" id="savingsWidget">
            <SavingsWidget savings={getCheckList()} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
