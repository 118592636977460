import {
  SET_CURRENT_STEP,
  NEXT_CURRENT_STEP,
  PREV_CURRENT_STEP,
  COMPLETE_STEP,
  SET_ORGANIZATION_ID,
} from '../../actions/types';

const INITIAL_STATE = {
  currentStep: 1,
  organizationId: null,
  steps: {
    createOrganization: {
      route: '/create-organization',
      completed: false,
    },
    inviteCoworkers: {
      route: '/invite-coworkers',
      completed: false,
    },
  },
};

export default function onboardingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case COMPLETE_STEP:
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload]: {
            ...state.steps[action.payload],
            completed: true,
          },
        },
      };
    case SET_ORGANIZATION_ID:
      return {
        ...state,
        organizationId: action.payload,
      };
    case NEXT_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case PREV_CURRENT_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    default:
      return state;
  }
}
